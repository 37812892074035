import React, {Component} from 'react';

class Footer extends Component {
  render(){
    const year = new Date().getFullYear();
        return (
            <section class="contact-section bg-black">
            <footer class="bg-black text-center text-white-50"><p class="container">Copyright © Jose Daniel Triana Restrepo {year}</p></footer>
            <br/>
            <br/>
        </section>
        )
    }
}

export default Footer;